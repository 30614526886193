$form-check-input-checked-bg-color: #d9a219;

@import "bootstrap/scss/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";

ngb-typeahead-window {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

@import "./themify.scss";
@import "./style.scss";
@import "./material.scss";
@import "./colors";
