// *** utils ***
@import "utils/variables";
@import "src/assets/scss/material/light.css";

.light {
  @extend .light;
  --theme-default: var(--md-sys-color-primary);
  --theme-secondary: #FF6150;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2F2F3B;
  --chart-border: #ECF3FA;
  --recent-chart-bg: #FCFCFD;
  --light-bg: #F6F7F9;
  --white: #fff;
  --light2: #F5F6F9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #F2F4F7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #F2F3F7;
  --course-bg: #F7F7F7;
  --balance-profie-bg: #E0DFEF;
  --view-grid-bg: #ECF3FA80;
  --view-border-marker: #cfcdfc;

  --fc-button-bg-color: var(--theme-default);
  --fc-button-active-bg-color: var(--theme-default);
  --fc-button-active-border-color: var(--theme-default);
  --fc-button-border-color: var(--theme-default);
  --fc-button-hover-bg-color: var(--theme-default);
  --fc-button-hover-border-color: var(--theme-default);

  // rating
  --normal: #ECEAF3;
  --normal-shadow: #D9D8E3;
  --normal-mouth: #9795A4;
  --normal-eye: #595861;
  --active: #F8DA69;
  --active-shadow: #F4B555;
  --active-mouth: #F05136;
  --active-eye: #313036;
  --active-tear: #76b5e7;
  --active-shadow-angry: #e94f1d;
}

body[class='dark-only'] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --bs-heading-color: rgba(255, 255, 255, 0.6);
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
}

@import "themes/update";
@import "base/reset";
@import "base/typography";
@import "components/card";
@import "components/table";
@import "components/forms";
@import "components/radio";
@import "pages/login";
@import "layout/header";
@import "layout/sidebar";
